import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Box, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import NumberFormat from "react-number-format";
import s from "./ModalCall.module.scss";
import { guestAPI } from "../../api/guestAPI";
import { LoadingButton } from "@mui/lab";
import ReactInputMask from "react-input-mask";
import { Text } from "../../language/lang";
import { NavLink } from "react-router-dom";

export default function ModalCall(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [isCompleted, setCopmleted] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const sendForm = (e) => {
    e.preventDefault();
    const fd = Object.fromEntries(new FormData(e.target).entries());

    setLoading(true);
    guestAPI
      .requireCallback({
        name: fd.name,
        message: fd.message,
        email: fd.email,
        phone: fd.phone,
      })
      .then((data) => {
        setLoading(false);
        if (Boolean(data.ok)) {
          setCopmleted(true);
        } else {
          enqueueSnackbar("Что-то пошло не так...", { variant: "error" });
        }
      });
  };

  return (
    <div>
      <div onClick={handleOpen}>{props.children}</div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper className={s.modal} sx={{ outline: "none" }}>
            {/* {isCompleted && (
              <div>
                <h3 style={{ textAlign: "center" }}>
                  Спасибо! Мы свяжемся с Вами!
                </h3>
              </div>
            )} */}
            {/* {!isCompleted && (
              <form method="post" onSubmit={sendForm}>
                <h3 className={s.modal__title}>Обратная связь</h3>
                <TextField
                  label="Ваше имя"
                  variant="standard"
                  name="name"
                  required
                />
                <TextField
                  label="Email"
                  variant="standard"
                  name="email"
                  type="email"
                  required
                />
                <ReactInputMask
                  mask={"+7 (999) 999 - 99 - 99"}
                  maskplaceholder={"X"}
                  placeholder="(XXX) XXX - XX - XX"
                >
                  {(inputProps) => (
                    <TextField
                      {...inputProps}
                      label={Text.Base.PhoneNumber}
                      variant="standard"
                      name="phone"
                      required
                    />
                  )}
                </ReactInputMask>
                <TextField
                  label="Сообщение"
                  multiline
                  maxRows={4}
                  value={value}
                  onChange={handleChange}
                  variant="standard"
                  name="message"
                />
                <LoadingButton
                  loading={isLoading}
                  color="secondary"
                  variant="outlined"
                  type="submit"
                >
                  Отправить
                </LoadingButton>
              </form>
            )} */}
            <Box
              sx={{
                fontSize: "20px",
                color: "var(--blue-color)",
                textAlign: "center",
                fontWeight: 700,
              }}
            >
              Если у вас возникли проблемы, напишите нам на почту
            </Box>
            <Box sx={{ fontSize: "20px", color: "brown" }}>
              fightevent@yandex.ru
            </Box>
            <NavLink
              to="mailto:fightevent@yandex.ru"
              className={s["email"]}
              title="Связаться по почте"
            >
              <span>Написать на почту</span>
            </NavLink>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
}
