import React, { useState, useEffect } from "react";
import styles from "./EventInfo.module.scss"; // Подключаем SCSS модуль
import { ROOT_URL } from "../../constants";
import ControlledModal from "../ControlledModal/ControlledModal";
import EventRegistrationContainer from "../EventRegistration/EventRegistrationContainer";
import RegistrationPayment from "../RegistrationPayment/RegistrationPayment";
import { StatusLine } from "../StatusLine/StatusLine";
import { getEventState } from "../../redux/selectors/getEventState";
import { getCurrentLanguage, Language, Text } from "../../language/lang";
import { FaTelegram, FaVk, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../../logo_new.png";
import { eventAPI } from "../../api/eventAPI";
import Carousel from "../Swiper/Carousel";
import { useDispatch, useSelector } from "react-redux";
import {
  getResultsByClubs,
  getWinners,
} from "../../redux/reducers/event/eventThunks.ts";
import DocumentsPolicyContainer from "./InfoBloki/DocumentsPolicyContainer.jsx";

const EventInfo = ({ data, isAdmin, cookieUserID, finishEvent }) => {
  const [isOpenFinish, setIsOpenFinish] = useState(false);
  const [isOpenRegistration, setOpenRegistration] = useState(false);
  const [isOpenPayment, setOpenPayment] = useState(false);
  const [isProgressBar, setProgressBar] = useState(false);
  const { isFetching } = useSelector(getEventState);
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);
  const [countdown, setCountdown] = useState("");
  const [activeAccordion, setActiveAccordion] = useState(null);
  const handleOpenFinish = () => setIsOpenFinish(true);
  const handleCloseFinish = () => setIsOpenFinish(false);
  const handleOpenRegistration = () => setOpenRegistration(true);
  const handleCloseRegistration = () => setOpenRegistration(false);
  const handleClosePayment = () => setOpenPayment(false);
  // Функция для управления отображением полного описания
  const dispatch = useDispatch();
  const toggleDescription = () => {
    setDescriptionExpanded(!isDescriptionExpanded);
  };
  const lang = () => (getCurrentLanguage() === "ru" ? true : false);

  const { resultsByClubs } = useSelector(getEventState);
  const dateUnixNow = Date.now();
  const dateDiscount = JSON.parse(data.discount);
  const getCheduleStatus = () => {
    const date = new Date();
    const hours = date.getHours();
    const day = date.getDay();

    if ((day === 0 || day === 6) && hours >= 12 && hours < 16) {
      return true;
    } else if (hours >= 10 && hours < 18) {
      return true;
    } else {
      return false;
    }
  };
  getCheduleStatus();
  const handleAccordion = (stage) => {
    switch (stage) {
      case "early":
        activeAccordion === "early"
          ? setActiveAccordion(null)
          : setActiveAccordion("early");
        break;
      case "average":
        activeAccordion === "average"
          ? setActiveAccordion(null)
          : setActiveAccordion("average");
        break;
      case "late":
        activeAccordion === "late"
          ? setActiveAccordion(null)
          : setActiveAccordion("late");
        break;
    }
  };
  // Логика работы таймера обратного отсчета
  useEffect(() => {
    dispatch(getResultsByClubs({ id: data.id }));
  }, []);
  useEffect(() => {
    const deadlines = {
      early: data.date_start_registration,
      middle:
        Number(data.date_end_registration - data.date_start_registration) / 2 +
        Number(data.date_start_registration),
      late: data.date_end_registration,
    };

    let currentDeadline = deadlines.early;
    const today = new Date();

    if (today > deadlines.early && today <= deadlines.middle) {
      currentDeadline = deadlines.middle;
    } else if (today > deadlines.middle && today <= deadlines.late) {
      currentDeadline = deadlines.late;
    }

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = currentDeadline - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setCountdown(`${days}д ${hours}ч ${minutes}м ${seconds}с`);

      if (distance < 0) {
        clearInterval(interval);
        setCountdown("Регистрация закрыта");
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    const progress = eventAPI.GetEventProgress({ id: data.id });
    progress.then((data) => {
      const procent = ((data.count_completed / data.count) * 100).toFixed(2);
      setProgressBar({ ...data, procent: procent });
    });
  }, []);
  useEffect(() => {
    if (isFetching.register.proceed) {
      setOpenRegistration(false);
      if (!isAdmin && +data?.price !== 0) {
        setOpenPayment(true); // ВЕРНУТЬ ВНИЗ
      }
    }
  }, [isFetching.register.proceed]);

  const getEventDuration = () => {
    return new Date(Number(data.date_from)).toLocaleString("ru-RU", {
      year: "numeric",
      month: "long", // Вывод названия месяца полностью
      day: "numeric",
    });
  };
  const getDateAccordion = (stage) => {
    const date = (date) =>
      new Date(Number(date)).toLocaleString("ru-RU", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
    const early =
      dateDiscount.length > 2
        ? dateDiscount[0].dateTo
        : data.date_start_registration;

    const average =
      dateDiscount.length > 2
        ? dateDiscount[1].dateTo
        : Number(data.date_end_registration - data.date_start_registration) /
            2 +
          Number(data.date_start_registration);

    const late =
      dateDiscount.length > 2
        ? dateDiscount[2].dateTo
        : data.date_end_registration;

    switch (stage) {
      case "early":
        return date(early);
      case "average":
        return date(average);

      case "late":
        return date(late);
      case "earlyClosed":
        return dateUnixNow > early;
      case "averageClosed":
        return dateUnixNow > average;
      case "lateClosed":
        return dateUnixNow > late;
    }
  };
  return (
    <div className={styles.eventInfo}>
      {/* Основная информация о мероприятии */}
      <div className={styles.container}>
        <div className={styles.poster}>
          {data.image ? (
            <img
              className={styles.image}
              alt=""
              src={`${ROOT_URL}/img/events/${data.image}`}
            />
          ) : (
            <div className={styles.posterPlaceholder}>
              <div>
                Афиша
                <br />
                Загрузите изображение
              </div>
            </div>
          )}
        </div>
        <div className={styles.info}>
          <h1>{lang() ? data.name : data.name_eng}</h1>
          <p>{getEventDuration()}</p>
          <p>{data.address}</p>

          {(cookieUserID !== -1 || isAdmin) && (
            <>
              <button
                className={styles.registerBtn}
                onClick={handleOpenRegistration}
              >
                {Text.EventInfo.Registration.Register}
              </button>
              <ControlledModal
                contentClassName={styles.modal}
                isOpen={isOpenRegistration}
                onClose={handleCloseRegistration}
                title={
                  <h3>{Text.EventInfo.Registration.RegistrationOnEvent}</h3>
                }
              >
                <EventRegistrationContainer />
              </ControlledModal>
              {!isAdmin && (
                <ControlledModal
                  isOpen={isOpenPayment}
                  onClose={handleClosePayment}
                  title={<h3>Оплата</h3>}
                >
                  <RegistrationPayment />
                </ControlledModal>
              )}
            </>
          )}
          {cookieUserID === -1 && !isAdmin && (
            <StatusLine
              type="warning"
              text="Войдите в аккаунт, чтобы зарегистрироваться"
            />
          )}
          {/* {isAdmin && (
            <>
              <button
                className={styles.containerBtn}
                onClick={handleOpenFinish}
              >
                Завершить турнир
              </button>
              <ControlledModal
                isOpen={isOpenFinish}
                onClose={handleCloseFinish}
                title={<h3>Завершить турнир?</h3>}
              >
                <div className={styles.finishbuttons}>
                  <button
                    className={styles.containerBtn}
                    onClick={handleCloseFinish}
                  >
                    Отмена
                  </button>
                  <button className={styles.containerBtn} onClick={finishEvent}>
                    Завершить
                  </button>
                </div>
              </ControlledModal>
            </>
          )} */}
          <div className={styles.badges}>
            <div className={styles.badge}>
              Участники: {JSON.parse(data.users).length}
            </div>
          </div>
        </div>

        <div>
          {/* Аккордеон с этапами регистрации */}
          <div className={styles.accordion}>
            <div
              className={`${styles.accordionItem} ${
                activeAccordion === "early" || activeAccordion == null
                  ? styles.active
                  : styles.hide
              }`}
              onClick={() => handleAccordion("early")}
            >
              <div
                className={`${styles.accordionHeader} ${
                  getDateAccordion("earlyClosed") ? styles.closed : ""
                }`}
              >
                Ранняя регистрация до {getDateAccordion("early")}
              </div>
              {activeAccordion === "early" && (
                <div className={styles.accordionContent}>
                  <p>1 категория - 1000 рублей</p>
                  <p>2 категория + 800 рублей</p>
                  <p>3 категория + 600 рублей</p>
                  <p>4 категория + 400 рублей</p>
                  <p>Дополнительные + 1000 рублей</p>
                </div>
              )}
            </div>

            <div
              className={`${styles.accordionItem} ${
                activeAccordion === "average" || activeAccordion == null
                  ? styles.active
                  : styles.hide
              }`}
              onClick={() => handleAccordion("average")}
            >
              <div
                className={`${styles.accordionHeader} ${
                  getDateAccordion("averageClosed") ? styles.closed : ""
                }`}
              >
                Средняя регистрация до {getDateAccordion("average")}
              </div>
              {activeAccordion === "average" && (
                <div className={styles.accordionContent}>
                  <p>1 категория - 2000 рублей</p>
                  <p>2 категория + 1800 рублей</p>
                  <p>3 категория + 1600 рублей</p>
                  <p>4 категория + 1400 рублей</p>
                  <p>Дополнительные + 1000 рублей</p>
                </div>
              )}
            </div>

            <div
              className={`${styles.accordionItem} ${
                activeAccordion === "late" || activeAccordion == null
                  ? styles.active
                  : styles.hide
              }`}
              onClick={() => handleAccordion("late")}
            >
              <div
                className={`${styles.accordionHeader} ${
                  getDateAccordion("lateClosed") ? styles.closed : ""
                }`}
              >
                Поздняя регистрация до {getDateAccordion("late")}
              </div>
              {activeAccordion === "late" && (
                <div className={styles.accordionContent}>
                  <p>1 категория - 3000 рублей</p>
                  <p>2 категория + 2800 рублей</p>
                  <p>3 категория + 2600 рублей</p>
                  <p>4 категория + 2400 рублей</p>
                  <p>Дополнительные + 1000 рублей</p>
                </div>
              )}
            </div>
          </div>
          {/* Таймер */}
          До конца этапа:
          <div className={styles.timer}> {countdown}</div>
        </div>
      </div>

      {/* Прогресс бар */}
      {isProgressBar && (
        <div className={styles.progressBarsContainer}>
          {isProgressBar.type == 1 && (
            <div className={styles.progressBar}>
              <div className={styles.progressBarContent}>
                Планируется: {isProgressBar.count} боев |{" "}
                {isProgressBar.platforms} площадки | Общее время:{" "}
                {isProgressBar.time}
              </div>
            </div>
          )}
          {isProgressBar.type === 2 && (
            <div className={styles.progressBarWithWave}>
              <div
                className={styles.waveContainer}
                style={{ width: isProgressBar.procent + "%" }}
              >
                <div className={styles.wave}></div>
              </div>
              <div className={styles.progressBarText}>
                Прогресс: {isProgressBar.procent}% | Осталось:{" "}
                {isProgressBar.count_completed}/{isProgressBar.count} боев |{" "}
                {isProgressBar.time_completed}/{isProgressBar.time}
              </div>
            </div>
          )}
        </div>
      )}

      {/* Описание мероприятия */}
      <div className={styles.descriptionContainer}>
        <div
          className={`${styles.description} ${
            isDescriptionExpanded ? styles.expanded : ""
          }`}
        >
          {lang() ? data.description : data.description_eng}
        </div>
        <button className={styles.readMoreBtn} onClick={toggleDescription}>
          {isDescriptionExpanded ? "Скрыть" : "Читать полностью"}
        </button>
      </div>
      <div className={styles.organizerContainer}>
        <div className={styles.logoContainer}>
          <div className={styles.logoPlaceholder}>
            <img src={logo} />
          </div>
        </div>

        <div className={styles.organizerDetails}>
          <h2>
            Организатор: Федерация Смешанных Боевых Искусств Свердловской
            области
          </h2>

          <p>Проведено мероприятий: 15</p>

          <div className={styles.contactInfo}>
            <Link to="tel:+74991133500">Телефон: {data.phone}</Link>
            <Link to={`mailto:${"federation@example.com"}`}>
              Почта: {data.email}
            </Link>

            <div className={styles.socialMedia}>
              <Link to="https://t.me/fsemma">
                <FaTelegram />
              </Link>
              <Link to="https://vk.com/fsemma">
                <FaVk />
              </Link>

              <Link to="https://wa.me/79951133500?text=Здравствуйте,%20у%20меня%20есть%20вопрос%20по%20соревнованиям">
                <FaWhatsapp />
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.schedule}>
          <div
            className={`${styles.statusBadge} ${
              !getCheduleStatus() ? styles.statusBadgeRed : ""
            }`}
          >
            {getCheduleStatus() ? "На связи" : " Недоступен"}
          </div>
          <h3>Время для связи (Москва)</h3>
          <p>Понедельник: 10:00 - 18:00</p>
          <p>Вторник: 10:00 - 18:00</p>
          <p>Среда: 10:00 - 18:00</p>
          <p>Четверг: 10:00 - 18:00</p>
          <p>Пятница: 10:00 - 18:00</p>
          <p>Суббота: 12:00 - 16:00</p>
          <p>Воскресенье: 12:00 - 16:00</p>
        </div>
      </div>
      {resultsByClubs != null && resultsByClubs.length > 0 && (
        <Carousel items={resultsByClubs} />
      )}
      <DocumentsPolicyContainer />
    </div>
  );
};

export default EventInfo;
