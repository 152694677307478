import React from "react";
import styles from "./ParticipantCard.module.css";
import Avatar from "@mui/material/Avatar";
// Компонент для отображения карточки участника
function ParticipantCard({ participant }) {
  //   console.log(participant);

  // Определение иконки и класса статуса
  let statusIcon = "";
  let statusText = "";
  let statusClass = "";

  if (participant.status === 3) {
    statusIcon = <i className="fas fa-credit-card"></i>;
    statusText = "Оплачен";
    statusClass = styles.statusOpla;
  } else if (participant.status === 4) {
    statusIcon = <i className="fas fa-check-circle"></i>;
    statusText = "Взвешен";
    statusClass = styles.statusVzveshen;
  } else if (participant.status === 6) {
    statusIcon = <i className="fas fa-ruble-sign"></i>;
    statusText = "Не оплачен";
    statusClass = styles.statusNeopla;
  }

  return (
    <div className={styles.participantCard}>
      <div className={styles.statusContainer}>
        <div className={styles.statusRow}>
          <div className={`${styles.statusSticker} ${statusClass}`}>
            {statusIcon}
          </div>
          <span className={styles.statusText}>{statusText}</span>
        </div>
        {/* {status === "Не оплачен" && (
          <>
            <div className={styles.debtInfo}>{debt} ₽</div>
            <button className={styles.payButton}>Оплатить</button>
          </>
        )} */}
      </div>
      {/* <div className={styles.actionButtons}>
        <i className="fas fa-edit" title="Изменить"></i>
        <i className="fas fa-trash-alt" title="Удалить"></i>
      </div> */}
      {participant.image == "" ? (
        <Avatar sx={{ width: "60px", height: "60px" }} />
      ) : (
        <img
          src={"https://pvkgym.ru/fightevent/img/users/" + participant?.image}
          alt={participant.fio}
        />
      )}

      <h2>{participant?.fio}</h2>
      <div className={styles.participantInfo}>
        <div className={styles.row}>
          <p className={styles.highlightedBorder}>
            {participant?.birthday}({participant?.old} лет)
          </p>{" "}
          | <p className={styles.highlightedBorder}>{participant?.weight} кг</p>
        </div>
        <div className={styles.row}>
          <p className={styles.highlightedBorder}>{participant?.category}</p> |{" "}
          <p className={styles.highlightedBorder}>
            {participant?.certification}
          </p>
        </div>
        <div className={styles.row}>
          <p>
            {participant?.club?.name}, {participant?.city},{" "}
            {participant?.region},{participant?.country}
          </p>
        </div>
      </div>
      <div className={styles.disciplineBadges}>
        {participant?.disciplines.map((discipline, index) => (
          <span key={index} className={styles.badge}>
            {discipline}
          </span>
        ))}
      </div>
    </div>
  );
}

export default ParticipantCard;
