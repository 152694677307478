import { selectionAPI } from "../../api/selectionAPI";

// * ===================================== TYPES =====================================
interface State {
  isFetching: Fetching;
  trainers: any[];
  managers: any[];
  sportsmen: any[];
  directors: any[];
  trainersForClub: any[];
  managersForClub: any[];
  usersForClub: any[];
  clubs: any[];
  clubsByRating: any[];
  users: any[];
  confirmations: any[];
  usersByRating: any[];
  events: any[];
  sportsmenForTrainer: any[];
  trainersForUser: any[];
  verifiedUsersOfTrainer: any[];
  directorsSportsmen: any[];
  eventsSelectionMode?: string;
}

interface Action {
  type: string;
  isFetching?: Fetching;
  trainers?: any[];
  managers?: any[];
  sportsmen?: any[];
  directors?: any[];
  trainersForClub?: any[];
  managersForClub?: any[];
  usersForClub?: any[];
  clubs?: any[];
  clubsByRating?: any[];
  users?: any[];
  confirmations?: any[];
  usersByRating?: any[];
  events?: any[];
  sportsmenForTrainer?: any[];
  trainersForUser?: any[];
  verifiedUsersOfTrainer?: any[];
  directorsSportsmen?: any[];
  eventsSelectionMode?: string;
}

interface Fetching {
  trainers?: boolean;
  managers?: boolean;
  sportsmen?: boolean;
  directors?: boolean;
  clubs?: boolean;
  users?: boolean;
  confirmation?: boolean;
  events?: boolean;
  verifiedUsers?: boolean;
}

// * ===================================== ACTIONS =====================================

const SET_FETCHING = "SET_FETCHING_selection";
const SET_TRAINERS = "SET_TRAINERS";
const SET_MANAGERS = "SET_MANAGERS";
const SET_SPORTSMEN = "SET_SPORTSMEN";
const SET_DIRECTORS = "SET_DIRECTORS";
const SET_TRAINERS_FOR_CLUB = "SET_TRAINERS_FOR_CLUB";
const SET_MANAGERS_FOR_CLUB = "SET_MANAGERS_FOR_CLUB";
const SET_USERS_FOR_CLUB = "SET_USERS_FOR_CLUB";
const SET_CLUBS = "SET_CLUBS";
const SET_CLUBS_BY_RATING = "SET_CLUBS_BY_RATING";
const RESET_CLUBS_BY_RATING = "RESET_CLUBS_BY_RATING";
const SET_USERS = "SET_USERS";
const SET_USERS_BY_RATING = "SET_USERS_BY_RATING";
const RESET_USERS_BY_RATING = "RESET_USERS_BY_RATING";
const SET_CONFIRMATIONS = "SET_CONFIRMATIONS";
const SET_EVENTS = "selection/SET_EVENTS";
const RESET_EVENTS = "selection/RESET_EVENTS";
const SET_TRAINERS_FOR_USER = "SET_TRAINERS_FOR_USER";
const SET_SPORTSMEN_FOR_TRAINER = "SET_SPORTSMEN_FOR_TRAINER";
const SET_VERIFIED_USER_OF_TRAINER = "SET_VERIFIED_USER_OF_TRAINER";
const SET_DIRECTORS_SPORTSMEN = "SET_DIRECTORS_SPORTSMEN";
const SET_EVENTS_SELECTION_MODE = "SET_EVENTS_SELECTION_MODE";

// * ===================================== REDUCER ==========================================

let initialState: State = {
  isFetching: {
    trainers: false,
    clubs: false,
    sportsmen: false,
    directors: false,
    events: false,
    verifiedUsers: false,
  },
  trainers: [],
  managers: [],
  sportsmen: [],
  clubs: [],
  users: [],
  usersByRating: [],
  trainersForClub: [],
  managersForClub: [],
  usersForClub: [],
  confirmations: [],
  clubsByRating: [],
  directors: [],
  events: [],
  sportsmenForTrainer: [],
  trainersForUser: [],
  verifiedUsersOfTrainer: [],
  directorsSportsmen: [],
  eventsSelectionMode: "",
};

export default function selectionReducer(
  state = initialState,
  action: Action
): State {
  switch (action.type) {
    case SET_FETCHING:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          ...action.isFetching,
        },
      };
    case SET_TRAINERS:
      return {
        ...state,
        trainers: action.trainers,
      };
    case SET_MANAGERS:
      return {
        ...state,
        managers: action.managers,
      };
    case SET_SPORTSMEN:
      return {
        ...state,
        sportsmen: action.sportsmen,
      };
    case SET_DIRECTORS:
      return {
        ...state,
        directors: action.directors,
      };
    case SET_TRAINERS_FOR_CLUB:
      return {
        ...state,
        trainersForClub: action.trainersForClub,
      };
    case SET_MANAGERS_FOR_CLUB:
      return {
        ...state,
        managersForClub: action.managersForClub,
      };
    case SET_USERS_FOR_CLUB:
      return {
        ...state,
        usersForClub: action.usersForClub,
      };
    case SET_CLUBS:
      return {
        ...state,
        clubs: action.clubs,
      };
    case SET_USERS_BY_RATING:
      return {
        ...state,
        usersByRating: [...state.usersByRating, ...action.usersByRating],
      };
    case RESET_USERS_BY_RATING:
      return {
        ...state,
        usersByRating: [],
      };
    case SET_USERS:
      return {
        ...state,
        users: action.users,
      };
    case SET_CLUBS_BY_RATING:
      return {
        ...state,
        clubsByRating: [...state.clubsByRating, ...action.clubsByRating],
      };
    case RESET_CLUBS_BY_RATING:
      return {
        ...state,
        clubsByRating: [],
      };
    case SET_CONFIRMATIONS:
      return {
        ...state,
        confirmations: action.confirmations,
      };
    case RESET_EVENTS:
      return {
        ...state,
        events: [],
      };
    case SET_EVENTS:
      return {
        ...state,
        events: [...state.events, ...action.events],
      };
    case SET_TRAINERS_FOR_USER:
      return {
        ...state,
        trainersForUser: [
          ...state.trainersForUser,
          ...(action.trainers ? action.trainers : []),
        ],
      };
    case SET_SPORTSMEN_FOR_TRAINER:
      return {
        ...state,
        sportsmenForTrainer: action.sportsmen,
      };
    case SET_VERIFIED_USER_OF_TRAINER:
      return {
        ...state,
        verifiedUsersOfTrainer: action.verifiedUsersOfTrainer,
      };
    case SET_DIRECTORS_SPORTSMEN:
      return {
        ...state,
        directorsSportsmen: action.directorsSportsmen,
      };
    case SET_EVENTS_SELECTION_MODE:
      return {
        ...state,
        eventsSelectionMode: action.eventsSelectionMode,
      };
    default:
      return state;
  }
}

// * ===================================== ACTION CREATORS =====================================

export const setFetching = (isFetching: Fetching): Action => ({
  type: SET_FETCHING,
  isFetching: isFetching,
});
export const setTrainers = (trainers: any[]): Action => ({
  type: SET_TRAINERS,
  trainers: trainers,
});
export const setManagers = (managers: any[]): Action => ({
  type: SET_MANAGERS,
  managers: managers,
});
export const setSportsmen = (sportsmen: any[]): Action => ({
  type: SET_SPORTSMEN,
  sportsmen: sportsmen,
});
export const setDirectors = (directors: any[]): Action => ({
  type: SET_DIRECTORS,
  directors: directors,
});
export const setTrainersForClub = (trainersForClub: any[]): Action => ({
  type: SET_TRAINERS_FOR_CLUB,
  trainersForClub: trainersForClub,
});
export const setManagersForClub = (managersForClub: any[]): Action => ({
  type: SET_MANAGERS_FOR_CLUB,
  managersForClub: managersForClub,
});
export const setUsersForClub = (usersForClub: any[]): Action => ({
  type: SET_USERS_FOR_CLUB,
  usersForClub: usersForClub,
});
export const setClubs = (clubs: any[]): Action => ({
  type: SET_CLUBS,
  clubs: clubs,
});
export const setClubsByRating = (clubs: any[]): Action => ({
  type: SET_CLUBS_BY_RATING,
  clubsByRating: clubs,
});
export const resetClubsByRating = (): Action => ({
  type: RESET_CLUBS_BY_RATING,
});
export const setUsers = (users: any[]): Action => ({
  type: SET_USERS,
  users: users,
});
export const setUsersByRating = (users: any[]): Action => ({
  type: SET_USERS_BY_RATING,
  usersByRating: users,
});
export const resetUsersByRating = (): Action => ({
  type: RESET_USERS_BY_RATING,
});
export const setConfirmations = (confirmations: any[]): Action => ({
  type: SET_CONFIRMATIONS,
  confirmations: confirmations,
});
export const setEvents = (events: any[]): Action => ({
  type: SET_EVENTS,
  events: events,
});
export const resetEvents = (): Action => ({ type: RESET_EVENTS });
export const setSportsmenForTrainer = (sportsmen: any[]): Action => ({
  type: SET_SPORTSMEN_FOR_TRAINER,
  sportsmen,
});
export const setTrainersForUser = (trainers: any[]): Action => ({
  type: SET_TRAINERS_FOR_USER,
  trainers,
});
export const setVerifiedUsersOfTrainer = (users: any[]): Action => ({
  type: SET_VERIFIED_USER_OF_TRAINER,
  verifiedUsersOfTrainer: users,
});
export const setDirectorsSportsmen = (users: any[]): Action => ({
  type: SET_DIRECTORS_SPORTSMEN,
  directorsSportsmen: users,
});
export const setEventsSelectionMode = (mode: string): Action => ({
  type: SET_EVENTS_SELECTION_MODE,
  eventsSelectionMode: mode,
});

// * ===================================== HELPERS ==============================================

// * ===================================== THUNKS ==============================================

export const getTrainers = () => {
  return (dispatch) => {
    dispatch(setFetching({ trainers: true }));
    selectionAPI.getTrainers().then((data) => {
      dispatch(setFetching({ trainers: false }));
      dispatch(setTrainers(data.data));
    });
  };
};

export const getManagers = () => {
  return (dispatch) => {
    dispatch(setFetching({ managers: true }));
    selectionAPI.getManagers().then((data) => {
      dispatch(setFetching({ managers: false }));
      dispatch(setManagers(data.data));
    });
  };
};

export const getDirectors = () => {
  return (dispatch) => {
    dispatch(setFetching({ directors: true }));
    selectionAPI.getDirectors().then((data) => {
      dispatch(setFetching({ directors: false }));
      dispatch(setDirectors(data.data));
    });
  };
};

export const getSportsmen_short = () => {
  return (dispatch) => {
    dispatch(setFetching({ sportsmen: true }));
    selectionAPI.getSportsmen_short().then((data) => {
      dispatch(setFetching({ sportsmen: false }));
      dispatch(setSportsmen(data.data));
    });
  };
};

export const getClubs = () => {
  return (dispatch) => {
    dispatch(setFetching({ clubs: true }));
    selectionAPI.getClubs().then((data) => {
      dispatch(setFetching({ clubs: false }));
      dispatch(setClubs(data.data));
    });
  };
};

export const getClubsByRating = ({ count, page }) => {
  return (dispatch) => {
    dispatch(setFetching({ clubs: true }));
    selectionAPI.getClubsByRating({ count, page }).then((data) => {
      dispatch(setFetching({ clubs: false }));
      dispatch(setClubsByRating(data.data));
    });
  };
};

export const getUsers = () => {
  return (dispatch) => {
    dispatch(setFetching({ users: true }));
    selectionAPI.getUsers().then((data) => {
      dispatch(setFetching({ users: false }));
      dispatch(setUsers(data.data));
    });
  };
};

export const getUsersByRating = ({ count, page }) => {
  return (dispatch) => {
    dispatch(setFetching({ users: true }));
    selectionAPI.getUsersByRating({ count, page }).then((data) => {
      dispatch(setFetching({ users: false }));
      dispatch(setUsersByRating(data.data));
    });
  };
};

export const getTrainersForClub = () => {
  return (dispatch) => {
    dispatch(setFetching({ trainers: true }));
    selectionAPI.getTrainersForClub().then((data) => {
      dispatch(setFetching({ trainers: false }));
      dispatch(setTrainersForClub(data.data));
    });
  };
};

export const getManagersForClub = () => {
  return (dispatch) => {
    dispatch(setFetching({ managers: true }));
    selectionAPI.getManagersForClub().then((data) => {
      dispatch(setFetching({ managers: false }));
      dispatch(setManagersForClub(data.data));
    });
  };
};

export const getSportsmenForClub = () => {
  return (dispatch) => {
    dispatch(setFetching({ sportsmen: true }));
    selectionAPI.getSportsmenForClub().then((data) => {
      dispatch(setFetching({ sportsmen: false }));
      dispatch(setUsersForClub(data.data));
    });
  };
};

export const getConfirmations = () => {
  return (dispatch) => {
    dispatch(setFetching({ confirmation: true }));
    selectionAPI.getConfirmations().then((data) => {
      dispatch(setFetching({ confirmation: false }));
      dispatch(setConfirmations(data.data));
    });
  };
};

export const getEvents = ({ count, page, time = "all" }) => {
  return (dispatch) => {
    dispatch(setFetching({ events: true }));
    selectionAPI.getEvents({ count, page, time }).then((data) => {
      dispatch(setEvents(data.data));
      console.log(data);
      dispatch(setFetching({ events: false }));
    });
  };
};

export const getEventsById = ({ id, count, page }) => {
  return (dispatch) => {
    dispatch(setFetching({ events: true }));
    selectionAPI.getEventsById({ id, count, page }).then((data) => {
      dispatch(setFetching({ events: false }));
      dispatch(setEvents(data.data));
    });
  };
};

export const getSportsmenForTrainer = () => {
  return (dispatch) => {
    dispatch(setFetching({ sportsmen: true }));
    selectionAPI.getSportsmenForTrainer().then((data) => {
      dispatch(setFetching({ sportsmen: false }));
      dispatch(setSportsmenForTrainer(data.data));
    });
  };
};

export const getTrainersForUser = () => {
  return (dispatch) => {
    dispatch(setFetching({ trainers: true }));
    selectionAPI.getTrainersForUser().then((data) => {
      dispatch(setFetching({ trainers: false }));
      dispatch(setTrainersForUser(data.data));
    });
  };
};

export const getVerifiedUsersOfTrainer = (dataObj) => {
  return (dispatch) => {
    dispatch(setFetching({ verifiedUsers: true }));
    selectionAPI.getUsersOfTrainer(dataObj).then((data) => {
      dispatch(setFetching({ verifiedUsers: false }));
      dispatch(setVerifiedUsersOfTrainer(data.data));
    });
  };
};

export const getDirectorsSportsmen = (dataObj) => {
  return (dispatch) => {
    dispatch(setFetching({ verifiedUsers: true }));
    selectionAPI.getDirectorsSportsmen(dataObj).then((data) => {
      dispatch(setFetching({ verifiedUsers: false }));
      dispatch(setDirectorsSportsmen(data.data));
    });
  };
};

export const searchUsers = ({ query }) => {
  return (dispatch) => {
    dispatch(setFetching({ users: true }));
    selectionAPI.searchUsers({ query }).then((data) => {
      dispatch(setFetching({ users: false }));
      dispatch(setUsers(data.data));
    });
  };
};
