import React from "react";
import styles from "./Filters.module.css";

function Filters({
  filters,
  setFilters,
  toggleFilterVisibility,
  isFilterVisible,
  availableWeights,
}) {
  // Массивы данных для фильтров
  const disciplinesList = [
    "Хард-Контакт",
    "Фулл-Контакт",
    "Протект-Контакт",
    "Страйк-Контакт",
    "Лайт-Контакт",
    "Страйк-Поинт",
    "Страйк-Лайт",
    "Тактик-Контрол",
    "Клоус-Контрол",
  ];

  const agesList = [
    "4-5",
    "6-7",
    "8-9",
    "10-11",
    "12-13",
    "14-15",
    "16-17",
    "18+",
  ];

  const rankLevels = ["Новички", "Опытные", "Мастера"];
  const statusesList = ["Оплачен", "Не оплачен", "Взвешен"];
  const gendersList = ["Мужской", "Женский"];

  // Функция для обновления фильтров
  function updateFilter(field, value) {
    setFilters((prevFilters) => {
      let newValue = value;
      if (Array.isArray(prevFilters[field])) {
        newValue = prevFilters[field].includes(value)
          ? prevFilters[field].filter((v) => v !== value)
          : [...prevFilters[field], value];
      }
      return {
        ...prevFilters,
        [field]: newValue,
      };
    });
  }

  // Функция для обновления текстовых полей фильтра
  function handleInputChange(e) {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  }

  return (
    <div className={styles.filterContainer}>
      {/* <button onClick={toggleFilterVisibility} className={styles.filterToggle}>
        {isFilterVisible ? "Скрыть" : "Фильтр"}
      </button> */}

      {isFilterVisible && (
        <div className={styles.filters}>
          {/* Поля для поиска */}
          <div className={styles.filterGroup}>
            <input
              type="text"
              name="searchFio"
              placeholder="Поиск по ФИО"
              value={filters.searchFio}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="searchClub"
              placeholder="Поиск по клубу"
              value={filters.searchClub}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="searchCity"
              placeholder="Поиск по городу"
              value={filters.searchCity}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="searchRegion"
              placeholder="Поиск по региону"
              value={filters.searchRegion}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="searchCountry"
              placeholder="Поиск по стране"
              value={filters.searchCountry}
              onChange={handleInputChange}
            />
          </div>

          {/* Фильтры по дисциплинам */}
          <div className={styles.filterGroup}>
            {disciplinesList.map((discipline) => (
              <button
                key={discipline}
                className={
                  filters.disciplines.includes(discipline) ? styles.active : ""
                }
                onClick={() => updateFilter("disciplines", discipline)}
              >
                {discipline}
              </button>
            ))}
          </div>

          {/* Фильтры по возрасту */}
          <div className={styles.filterGroup}>
            {agesList.map((age) => (
              <button
                key={age}
                className={filters.ages.includes(age) ? styles.active : ""}
                onClick={() => updateFilter("ages", age)}
              >
                {age} лет
              </button>
            ))}
          </div>

          {/* Фильтры по весу */}
          <div className={styles.filterGroup}>
            {availableWeights.map((weight) => (
              <button
                key={weight}
                className={
                  filters.weights.includes(weight) ? styles.active : ""
                }
                onClick={() => updateFilter("weights", weight)}
              >
                {weight} кг
              </button>
            ))}
          </div>

          {/* Фильтры по разряду, полу и статусу */}
          <div className={styles.filterGroup}>
            {rankLevels.map((rankLevel) => (
              <button
                key={rankLevel}
                className={
                  filters.ranks.includes(rankLevel) ? styles.active : ""
                }
                onClick={() => updateFilter("ranks", rankLevel)}
              >
                {rankLevel}
              </button>
            ))}
            <div className={styles.separator}></div>
            {gendersList.map((gender) => (
              <button
                key={gender}
                className={
                  filters.genders.includes(gender) ? styles.active : ""
                }
                onClick={() => updateFilter("genders", gender)}
              >
                {gender}
              </button>
            ))}
            <div className={styles.separator}></div>
            {statusesList.map((status) => (
              <button
                key={status}
                className={
                  filters.statuses.includes(status) ? styles.active : ""
                }
                onClick={() => updateFilter("statuses", status)}
              >
                {status}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Filters;
