import React, { useState, useEffect } from "react";
import ParticipantCard from "./ParticipantCard";
import Filters from "./Filters";
import styles from "./ParticipantContainer.module.css";
import {
  getEventUsers,
  deleteFromEvent,
  updateUserDataOnEvent,
  getCountUsers,
  getUsersFileUrl,
  getEvent,
} from "../../redux/reducers/event/eventThunks.ts";
import { getCookie } from "../../AppExtComponents.ts";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getEventState } from "../../redux/selectors/getEventState";
import {
  getBasketRules,
  updateBasketRules,
} from "../../redux/reducers/settings/settingsThunks";

import * as XLSX from "xlsx";

// Импортируем массив участников
// import { participants } from "./part";

// Компонент для отображения списка участников и управления фильтрами
function ParticipantsContainerNew() {
  const { eventID } = useParams();
  const dispatch = useDispatch();
  // Состояние фильтров
  const [filters, setFilters] = useState({
    searchFio: "",
    searchClub: "",
    searchCity: "",
    searchRegion: "",
    searchCountry: "",
    disciplines: [],
    ages: [],
    weights: [],
    ranks: [],
    statuses: [],
    genders: [],
  });
  const {
    event,
    participants,

    disciplinesNames,
    usersFileUrl,
  } = useSelector(getEventState);
  // console.log(participants);
  // Отфильтрованные участники
  const [filteredParticipants, setFilteredParticipants] =
    useState(participants);

  // Отображение фильтров
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  // Сортировка
  const [sortConfig, setSortConfig] = useState(null);

  // Доступные веса на основе выбранных возрастов
  const [availableWeights, setAvailableWeights] = useState([]);

  // Массивы данных для фильтров
  const weightsByAge = {
    "4-5": ["-16", "-18", "-20", "-22", "-24", "-26", "-29", "-32", "32+"],
    "6-7": ["-20", "-22", "-24", "-26", "-29", "-32", "-35", "-38", "38+"],
    "8-9": ["-24", "-26", "-29", "-32", "-35", "-38", "-41", "-44", "44+"],
    "10-11": ["-29", "-32", "-35", "-38", "-41", "-44", "-48", "-52", "52+"],
    "12-13": ["-35", "-38", "-41", "-44", "-48", "-52", "-56", "-61", "61+"],
    "14-15": ["-41", "-44", "-48", "-52", "-56", "-61", "-66", "-71", "71+"],
    "16-17": ["-48", "-52", "-56", "-61", "-66", "-71", "-77", "-84", "84+"],
    "18+": ["-56", "-61", "-66", "-71", "-77", "-84", "-92", "-100", "100+"],
  };
  useEffect(() => {
    dispatch(getEvent({ id: eventID }));
    dispatch(
      getEventUsers({
        id: eventID,
        page: 1,
        count: 1000,
        user_id: getCookie("user") ? getCookie("user") : "",
      })
    );
    dispatch(getBasketRules({ id: eventID }));
    dispatch(getCountUsers({ id: eventID }));
  }, []);

  // Обновление списка участников при изменении фильтров
  useEffect(() => {
    applyFilters();
  }, [filters]);

  // Обновление доступных весовых категорий при изменении возраста
  useEffect(() => {
    const selectedAges = filters.ages;
    let weights = new Set();

    selectedAges.forEach((age) => {
      weightsByAge[age]?.forEach((weight) => {
        weights.add(weight);
      });
    });

    setAvailableWeights(Array.from(weights));
  }, [filters.ages]);

  // Функция для применения фильтров
  function applyFilters() {
    // let filtered = participants.filter((participant) => {
    //   const matchesFio = participant.fio
    //     .toLowerCase()
    //     .includes(filters.searchFio.toLowerCase());
    //   const matchesClub = participant.club.name
    //     .toLowerCase()
    //     .includes(filters.searchClub.toLowerCase());
    //   const matchesCity = participant.city
    //     .toLowerCase()
    //     .includes(filters.searchCity.toLowerCase());
    //   const matchesRegion = participant.region
    //     .toLowerCase()
    //     .includes(filters.searchRegion.toLowerCase());
    //   const matchesCountry = participant.country
    //     .toLowerCase()
    //     .includes(filters.searchCountry.toLowerCase());
    //   const matchesDiscipline =
    //     filters.disciplines.length === 0 ||
    //     filters.disciplines.some((d) => participant.disciplines.includes(d));
    //   const matchesAge =
    //     filters.ages.length === 0 ||
    //     filters.ages.includes(participant.ageGroup);
    //   const matchesWeight =
    //     filters.weights.length === 0 ||
    //     filters.weights.includes(participant.weight);
    //   const matchesRank =
    //     filters.ranks.length === 0 ||
    //     filters.ranks.includes(participant.rankLevel);
    //   const matchesStatus =
    //     filters.statuses.length === 0 ||
    //     filters.statuses.includes(participant.status);
    //   const matchesGender =
    //     filters.genders.length === 0 ||
    //     filters.genders.includes(participant.gender);
    //   return (
    //     matchesFio &&
    //     matchesClub &&
    //     matchesCity &&
    //     matchesRegion &&
    //     matchesCountry &&
    //     matchesDiscipline &&
    //     matchesAge &&
    //     matchesWeight &&
    //     matchesRank &&
    //     matchesStatus &&
    //     matchesGender
    //   );
    // });
    // // Применение сортировки после фильтрации
    // if (sortConfig !== null) {
    //   filtered.sort((a, b) => {
    //     if (a[sortConfig.key] < b[sortConfig.key]) {
    //       return sortConfig.direction === "asc" ? -1 : 1;
    //     }
    //     if (a[sortConfig.key] > b[sortConfig.key]) {
    //       return sortConfig.direction === "asc" ? 1 : -1;
    //     }
    //     return 0;
    //   });
    // }
    // setFilteredParticipants(filtered);
  }

  // Функция для переключения отображения фильтров
  function toggleFilterVisibility() {
    setIsFilterVisible(!isFilterVisible);
  }

  // Функция для экспорта данных в Excel
  function exportToExcel() {
    const dataToExport = filteredParticipants.map((participant) => ({
      ФИО: participant.fullName,
      Клуб: participant.club,
      Город: participant.city,
      Регион: participant.region,
      Страна: participant.country,
      "Дата рождения": participant.birthDate,
      Возраст: participant.ageGroup,
      Вес: participant.weight + " кг",
      Разряд: participant.rank,
      Пояс: participant.belt,
      Дисциплины: participant.disciplines.join(", "),
      Статус: participant.status,
      Пол: participant.gender,
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Участники");

    XLSX.writeFile(workbook, "Участники.xlsx");
  }

  // Функция для изменения сортировки
  function requestSort(key) {
    let direction = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  }

  // Функция для получения класса направления сортировки
  function getSortClassName(key) {
    if (!sortConfig || sortConfig.key !== key) {
      return "fas fa-sort";
    }
    return sortConfig.direction === "asc"
      ? "fas fa-sort-up"
      : "fas fa-sort-down";
  }

  return (
    <div className={styles.container}>
      {/* <button
          onClick={toggleFilterVisibility}
          className={styles.filterToggle}
        >
          {isFilterVisible ? "Скрыть" : "Фильтр"}
        </button>{" "} */}

      {/* Компонент фильтров */}
      {/* <Filters
        filters={filters}
        setFilters={setFilters}
        toggleFilterVisibility={toggleFilterVisibility}
        isFilterVisible={isFilterVisible}
        availableWeights={availableWeights}
      /> */}

      {/* Опции сортировки и кнопка экспорта */}
      {/* <div className={styles.statisticsSortContainer}>
        <div className={styles.sortOptions}>
          <div
            className={styles.sortOption}
            onClick={() => requestSort("fullName")}
          >
            <span>По алфавиту</span>
            <i className={getSortClassName("fullName")}></i>
          </div>
          <div className={styles.sortOption} onClick={() => requestSort("age")}>
            <span>По возрасту</span>
            <i className={getSortClassName("age")}></i>
          </div>
          <div
            className={styles.sortOption}
            onClick={() => requestSort("weight")}
          >
            <span>По весу</span>
            <i className={getSortClassName("weight")}></i>
          </div>
          <button onClick={exportToExcel} className={styles.downloadButton}>
            <i className="fas fa-download"></i> Скачать
          </button>
        </div>
      </div> */}

      {/* Список участников */}
      <div className={styles.participantsContainer}>
        {participants.map((participant, index) => (
          <ParticipantCard key={index} participant={participant} />
        ))}
      </div>
    </div>
  );
}

export default ParticipantsContainerNew;
