import s from "./Footer.module.sass";
import Button from "@mui/material/Button";
import ModalCall from "../ModalCall/ModalCall";
import EmailIcon from "@mui/icons-material/Email";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  getCurrentLanguage,
  Language,
  setCurrentLanguage,
  Text,
} from "../../language/lang";

const Footer = () => {
  const handleChangeLang = (e, newValue) => {
    setCurrentLanguage(newValue);
  };

  return (
    <div className={s.footer}>
      <ModalCall>
        <Button
          //   sx={{ color: "white", borderColor: "white" }}
          color="primary"
          variant="outlined"
        >
          {Text.Footer.Feedback}
          <EmailIcon sx={{ marginLeft: "10px" }} />
        </Button>
      </ModalCall>
      <ToggleButtonGroup
        value={getCurrentLanguage()}
        exclusive
        onChange={handleChangeLang}
      >
        <ToggleButton value={Language.RU}>RU</ToggleButton>
        <ToggleButton value={Language.EN}>ENG</ToggleButton>
      </ToggleButtonGroup>
      <span>©️ FIGHTEVENT.RU. {Text.Footer.AllRightsReserved}</span>
    </div>
  );
};

export default Footer;
